import React from 'react';

export default function TherapyList() {
    return (
        <div>
            <h3>Orlando's Premier Center for:</h3>
            <div>
                Effective treatment of sports injuries, back pain, arthritis, nerve pain, chronic & age-related conditions.
            </div>
            <ul>
                <li>Anti-Aging</li>
                <li>Vitamin Infusion</li>
                <li>Platet-Rich Plasma (PRP)</li>
                <li>ShockWave Muscle Therapy</li>
                <li>Bio-Identical Hormone Replacement</li>
            </ul>
        </div>
    );
}
