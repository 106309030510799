import React from 'react'

export default function PageSubTitle() {
    return (
        <h1 className='subtitle'>
            <span>Board-Certified Internal Medicine</span>
            <span>that Treats the Whole Person</span>
        </h1>
    );
}
