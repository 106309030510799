import React from 'react';

import Splash from './Splash.js';
import PageTitle from './PageTitle.js';
import PageSubTitle from './PageSubTitle.js';
import TherapyList from './TherapyList.js';
import Contact from './Contact.js';
import Address from './Address.js';

class HomePageLandscape extends React.Component {
  render () {
    const content = (
      <div>
        <div className='table'>
          <div style={{backgroundColor: 'white'}}>
            <div style={{verticalAlign: 'top'}}>
              <PageTitle />
              <div style={{marginTop: '0.5em'}}>
                <PageSubTitle />
              </div>
              <div style={{paddingRight: '0.1em'}}>
                <TherapyList />
              </div>
            </div>
            <div style={{width: '50%', paddingTop: '0.2em', paddingRight: '0.2em'}}>
              <Splash />
            </div>
          </div>
        </div>
        <div style={{padding: '0.5em', verticalAlign: 'middle'}}>
          <div className='table emphasize' style={{width: '100%', padding: 0, margin: 0}}>
            <div>
              <div style={{width: '50%'}}>
                <Contact />
              </div>
              <div style={{width: '50%'}}>
                <Address />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    
    return content;
  }
}

export default HomePageLandscape;
