import React from 'react';

export default function ContactUs () {
    return (
        <div className='emphasize'>
            <span className='block'>Call Us Today!</span>
            <span className='block'>Tel:&nbsp;&nbsp;&nbsp;<a href="tel://407-476-4011">(407) 476-4011</a></span>
            <span className='block'>FAX: (877) 326-3480</span>
        </div>
    );
}
