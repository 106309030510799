import React from 'react';

import Splash from './Splash.js';
import PageTitle from './PageTitle.js';
import PageSubTitle from './PageSubTitle.js';
import TherapyList from './TherapyList.js';
import Contact from './Contact.js';
import Address from './Address.js';

class HomePagePortrait extends React.Component {
  render () {
    const content = (
      <div>
        <div style={{backgroundColor: 'white'}}>
            <div style={{textAlign: 'center'}}>
                <PageTitle />
                <div style={{marginTop: '0.5em'}}>
                  <PageSubTitle />
                </div>
            </div>
            <div style={{textAlign: 'center', padding: '1em 1em 0 1em'}}>
                <Splash />
            </div>
            <div style={{paddingLeft: '0.5em', paddingBottom: '0.2em', paddingRight: '0.2em'}}>
                <TherapyList />
            </div>
        </div>
        <div style={{textAlign: 'center', paddingTop: '0.5em', paddingBottom: '0.5em'}}>
            <div>
              <Contact />
            </div>
            <div style={{marginTop: '0.5em'}}>
              <Address />
            </div>
        </div>
      </div>
    )
    
    return content;
  }
}

export default HomePagePortrait;
