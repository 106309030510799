import React from 'react';

export default function Splash() {
    return <img 
        src='/img/splash.jpg' 
        width='100%'
        style={{
            borderStyle: 'solid', 
            borderWidth: '3px', 
            borderColor: 'rgba(0,0,0,0.5)',
            borderRadius: '3px'
        }}

    />;
}
