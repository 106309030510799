import React from 'react';

export default function Address () {
    return (
        <div className='emphasize'>
            <a href="https://maps.app.goo.gl/nwFuGnzjBk7TwPxc6" target="_blank">
                <span className='block'>1711 Smith Street</span>
                <span className='block'>Kissimmee, FL 34744</span>
            </a>
        </div>
    );
}
