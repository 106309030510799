import React from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import HomePageLandscape from './HomePage/LandscapeLayout.js'
import HomePagePortrait from './HomePage/PortraitLayout.js'

class HomePage extends React.Component {
  render () {
    return (
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <HomePageLandscape />
        </Orientation>
        <Orientation orientation='portrait'  alwaysRender={false}>
          <HomePagePortrait />
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default HomePage;
