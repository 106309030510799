import './App.css';
import './FloridaPrecisionMD.css';

import React from 'react';
import HomePage from './Pages/HomePage.js'

class App extends React.Component {
  
  componentDidMount() {
  }
  render () {
    const content = (
       <div>
          <HomePage />
        </div>
    );
    return content;
  }
}

export default App;
